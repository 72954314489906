import React from 'react'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

const Bg = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "cart-categories" } }}
        ) {
          edges {
            node {
              frontmatter {
                categories{
                  category05{
                    image {
                      childImageSharp {
                        fluid(maxWidth: 500, quality: 80) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                        }
                      }
                    }
                    title
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <div className='imageContainer' style={{ position: 'relative' }}>
      <Img
        fluid={data.allMarkdownRemark.edges[0].node.frontmatter.categories.category05.image.childImageSharp.fluid}
        style={{
          width: '100%',
          height: '100vh',
          position: 'fixed',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          filter: 'brightness(40%)'
        }}
      />
    </div>
  )
}

export default Bg
